import Flickity from "flickity";
import LazyLoad from "vanilla-lazyload";
import Alpine from "alpinejs";
import gsap from "gsap";
import QrScanner from "qr-scanner";
import { Loader } from "@googlemaps/js-api-loader";
window.Alpine = Alpine;
Alpine.start();
const HTML = document.querySelector("html");
class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == void 0) {
          const figure = $el.closest("figure");
          if (figure)
            figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure)
          figure.classList.add("img-is-loaded");
      },
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      }
    });
    const sliders = document.querySelectorAll(".slider");
    if (sliders) {
      sliders.forEach((slider) => {
        new Flickity(slider, {
          pageDots: false,
          prevNextButtons: false,
          watchCSS: true,
          cellAlign: "left"
        });
      });
    }
    this.refresh();
    this.search();
    this.checking();
    this.panel();
    this.carousel();
    this.maps();
    this.slide();
    this.chat();
  }
  init(el) {
    this.form(el);
    this.popin(el);
  }
  popin(parent) {
    const triggers = parent.querySelectorAll("[data-popin]");
    if (triggers) {
      triggers.forEach((trigger) => {
        trigger.addEventListener("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          const target = parent.querySelector(trigger.dataset.popin);
          if (target) {
            let closeOverlay = function(ev) {
              if (!ev.target.classList.contains(".popin-content") && !ev.target.closest(".popin-content")) {
                close(ev);
              }
            }, close = function(ev) {
              ev.stopPropagation();
              ev.preventDefault();
              target.classList.remove("show");
              HTML.classList.remove("no-scroll");
            };
            HTML.classList.add("no-scroll");
            target.classList.add("show");
            document.body.removeEventListener("click", closeOverlay);
            document.body.addEventListener("click", closeOverlay);
            const closeEl = target.querySelectorAll(".trigger-close");
            if (closeEl) {
              closeEl.forEach((c) => {
                c.removeEventListener("click", close);
                c.addEventListener("click", close);
              });
            }
          }
        });
      });
    }
  }
  search() {
    const searchs = document.querySelectorAll('[name="search"]');
    if (searchs) {
      searchs.forEach((search) => {
        search.addEventListener("input", (e) => {
          const table = document.querySelector(search.dataset.target);
          if (table) {
            const filter = search.value.toUpperCase();
            const tr = table.querySelectorAll(".row:not(.table-header)");
            for (let i = 0; i < tr.length; i++) {
              const td = tr[i].querySelectorAll(".cell")[0];
              if (td) {
                const txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                  tr[i].style.display = "";
                } else {
                  tr[i].style.display = "none";
                }
              }
            }
          }
        });
      });
    }
  }
  form(parent) {
    const forms = parent.querySelectorAll("[data-form]");
    if (forms) {
      forms.forEach((form) => {
        form.addEventListener("submit", (e) => {
          e.preventDefault();
          fetch(form.dataset.form).then((response) => {
            const refreshs = document.querySelectorAll("[data-refresh]");
            if (refreshs) {
              refreshs.forEach((refresh) => {
                refresh.dispatchEvent(new Event("refresh"));
              });
            }
          }).catch((error) => {
            const refreshs = document.querySelectorAll("[data-refresh]");
            if (refreshs) {
              refreshs.forEach((refresh) => {
                refresh.dispatchEvent(new Event("refresh"));
              });
            }
          });
          return false;
        });
      });
    }
  }
  panel() {
    const panels = document.querySelectorAll(".panel");
    if (panels) {
      panels.forEach((panel) => {
        const triggers = panel.querySelectorAll(".panel-header a");
        const contents = panel.querySelectorAll(".panel-content");
        let current = -1;
        if (triggers && contents) {
          let goto = function(index) {
            if (index == current)
              return false;
            if (triggers[current]) {
              triggers[current].classList.remove("active");
              gsap.to(contents[current], {
                duration: 0.4,
                ease: "circ.inOut",
                x: `${index > current ? -100 : 100}%`
              });
            }
            triggers[index].classList.add("active");
            gsap.fromTo(contents[index], {
              autoAlpha: 1,
              x: `${index > current ? 100 : -100}%`
            }, {
              duration: 0.4,
              ease: "circ.inOut",
              x: "0%",
              onComplete: () => {
                gsap.set(contents[index], { clearProps: "x" });
              }
            });
            const refreshs = contents[index].querySelectorAll("[data-refresh]");
            if (refreshs) {
              refreshs.forEach((refresh) => {
                refresh.dispatchEvent(new Event("refresh"));
              });
            }
            current = index;
          };
          triggers.forEach((trigger, i) => {
            trigger.addEventListener("click", (e) => {
              e.preventDefault();
              goto(i);
            });
          });
          goto(0);
        }
      });
    }
  }
  refresh() {
    const zones = document.querySelectorAll("[data-refresh]");
    if (zones) {
      zones.forEach((zone) => {
        zone.addEventListener("refresh", () => {
          const data = new FormData();
          data.append("action", zone.dataset.refresh);
          zone.innerHTML = "";
          if (zone.dataset.refreshParams && JSON.parse(zone.dataset.refreshParams)) {
            const params = JSON.parse(zone.dataset.refreshParams);
            for (const i in params) {
              data.append(i, params[i]);
            }
          }
          const timer = setTimeout(() => {
            zone.classList.add("loading");
          }, 500);
          fetch("/inc/.ajax.php", {
            method: "POST",
            body: data
          }).then((response) => response.text()).then((response) => {
            clearTimeout(timer);
            zone.classList.remove("loading");
            if (response) {
              zone.innerHTML = response;
              this.init(zone);
            }
          }).catch((error) => {
            clearTimeout(timer);
            zone.classList.remove("loading");
            console.log(error);
          });
        });
      });
    }
  }
  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
  checking() {
    const _ = this;
    const frames = document.querySelectorAll(".frame-checking");
    if (frames) {
      frames.forEach((frame) => {
        let isScanning = false;
        const video = frame.querySelector("video");
        const overlay = frame.querySelector(".overlay");
        const msg = overlay.querySelector(".msg");
        const content = msg.querySelector(".content");
        const qrScanner = new QrScanner(video, (result) => {
          if (_.isValidHttpUrl(result.data) && !isScanning) {
            overlay.classList.add("loading");
            overlay.classList.remove("opacity-0", "invisible");
            isScanning = true;
            fetch(result.data + "?formApp=true").then((response) => response.json()).then((response) => {
              if (response) {
                msg.classList.remove("bg-green-400", "bg-red-400");
                msg.classList.add(response.statut ? "bg-green-400" : "bg-red-400");
                content.innerHTML = response.msg;
                gsap.fromTo(msg, {
                  autoAlpha: 0,
                  y: "4rem"
                }, {
                  duration: 0.4,
                  autoAlpha: 1,
                  y: "0rem"
                });
              }
              overlay.classList.remove("loading");
            }).catch((error) => {
              alert(`Error: ${error}`);
              overlay.classList.add("opacity-0", "invisible");
              overlay.classList.remove("loading");
              qrScanner.start();
            });
          }
        }, {
          highlightScanRegion: true,
          highlightCodeOutline: true,
          returnDetailedScanResult: true
        });
        qrScanner.setCamera("environment");
        qrScanner.start();
        if (overlay) {
          const close = overlay.querySelector(".trigger-close");
          overlay.addEventListener("click", (e) => {
            if (!e.target.classList.contains("msg") && !e.target.closest(".msg")) {
              e.stopPropagation();
              e.preventDefault();
              overlay.classList.add("opacity-0", "invisible");
              isScanning = false;
            }
          });
          if (close) {
            close.addEventListener("click", (e) => {
              e.stopPropagation();
              e.preventDefault();
              overlay.classList.add("opacity-0", "invisible");
              isScanning = false;
            });
          }
        }
      });
    }
  }
  carousel() {
    const carousels = document.querySelectorAll(".carousel");
    if (carousels) {
      carousels.forEach((carousel) => {
        const next = carousel.dataset.next ? document.querySelector(carousel.dataset.next) : carousel.querySelector(".next");
        const prev = carousel.dataset.prev ? document.querySelector(carousel.dataset.prev) : carousel.querySelector(".prev");
        const flkty = new Flickity(carousel, {
          cellAlign: "left",
          contain: true,
          groupCells: true,
          cellSelector: ".item",
          pageDots: false,
          prevNextButtons: false,
          on: {
            change: function(index) {
              if (index == 0) {
                prev.classList.add("disabled");
              } else {
                prev.classList.remove("disabled");
              }
              if (index + 3 == this.cells.length) {
                next.classList.add("disabled");
              } else {
                next.classList.remove("disabled");
              }
            }
          }
        });
        if (next) {
          next.addEventListener("click", (e) => {
            e.preventDefault();
            flkty.next();
          });
        }
        if (prev) {
          prev.addEventListener("click", (e) => {
            e.preventDefault();
            flkty.previous();
          });
        }
      });
    }
  }
  maps() {
    const maps = document.querySelectorAll(".map");
    if (maps) {
      const loader = new Loader({
        apiKey: "AIzaSyCZ9dhTFpyCMKtcTQGBeyJuwqjo07nm0Wc"
      });
      loader.load().then(() => {
        maps.forEach((map) => {
          if (map.dataset.latlng) {
            const latlng = map.dataset.latlng.split(",");
            const point = { lat: parseFloat(latlng[0]), lng: parseFloat(latlng[1]) };
            const m = new google.maps.Map(map, {
              center: point,
              zoom: 13
            });
            const marker = new google.maps.Marker({
              position: point,
              map: m
            });
          }
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  }
  slide() {
    const elts = document.querySelectorAll("[data-slide]");
    if (elts) {
      elts.forEach((el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();
          const target = document.querySelector(el.dataset.slide);
          if (target) {
            const h = target.classList.contains("open") ? 0 : target.children[0].offsetHeight;
            gsap.to(target, { duration: 0.3, autoAlpha: target.classList.contains("open") ? 0 : 1, height: h });
            target.classList.toggle("open");
          }
        });
      });
    }
  }
  chat() {
    const elts = document.querySelectorAll(".chat-container");
    if (elts) {
      elts.forEach((el) => {
        const f = el.querySelector("form");
        const trigger = el.querySelector(".trigger");
        const overlay = document.querySelector(".overlay");
        if (overlay) {
          overlay.addEventListener("click", (e) => {
            HTML.classList.remove("show-chat");
          });
        }
        if (trigger) {
          trigger.addEventListener("click", (e) => {
            e.preventDefault();
            HTML.classList.toggle("show-chat");
          });
        }
        if (f) {
          f.addEventListener("submit", (e) => {
            e.preventDefault();
            f.classList.add("loading");
            const data = new FormData();
            data.append("action", "chat");
            for (var i = 0; i < f.elements.length; i++) {
              const input = f.elements[i];
              data.append(input.name, input.value);
            }
            fetch("/inc/.ajax.php", {
              method: "POST",
              body: data
            }).then((response) => response.json()).then((response) => {
              f.classList.remove("loading");
              if (response.msg) {
                const msg = document.createElement("div");
                msg.classList.add("msg");
                msg.innerHTML = response.msg;
                f.prepend(msg);
                if (response.statut == "success") {
                  for (var i2 = 0; i2 < f.elements.length; i2++) {
                    const el2 = f.elements[i2];
                    el2.value = "";
                  }
                }
              }
            }).catch((error) => {
              const msg = document.createElement("div");
              msg.classList.add("msg");
              msg.innerHTML = "Une erreur s'est produite veuillez r\xE9essayer ult\xE9rieurement.";
              f.prepend(msg);
              f.classList.remove("loading");
            });
            return false;
          });
        }
      });
    }
  }
}
window.addEventListener("DOMContentLoaded", () => {
  new App();
});


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
